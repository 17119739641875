// FileUploader.tsx
import React, { ChangeEvent, useEffect, useState } from "react";
import mammoth from "mammoth";
import axios, { AxiosProgressEvent } from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  CheckboxGroup,
  VStack,
  HStack,
  Flex,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { taggedParagraph } from "../types/words";
import { omitElement } from "../common/utils";
import { simpleAPICall } from "../common/sse";
import { useNavigate } from "react-router-dom";

interface FileUploaderProps {
  onUploaded: (options: {
    file: File;
    language: string;
    reviewOptions: string[];
  }) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onUploaded }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [language, setLanguage] = useState<string>("简体中文");
  const [reviewOptions, setReviewOptions] = useState<string[]>([]);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [showWarning, setShowWarning] = useState(false);

  const handleUpload = (options: {
    file: File;
    language: string;
    reviewOptions: string[];
  }) => {
    console.log("上传选项：", options);
    setLanguage(options.language);
    setReviewOptions(options.reviewOptions);

    const handleFileSelect = (file: File) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const target = e.target as FileReader;
          handleFileRead(file.name, target.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(file);
      }
    };

    // 处理文件
    handleFileSelect(options.file);
  };

  const handleFileRead = (fn: string, file: ArrayBuffer) => {
    mammoth
      .convertToHtml({ arrayBuffer: file })
      .then((result) => {
        const docHtml = result.value;
        //setConvertedDocument(docHtml);
        //setFileName(fn);
        processDOM(fn, docHtml);

        // 设置 updateProcessDOM 为 true 以触发 processDOM 函数
        //setUpdateProcessDOM(true);
      })
      .catch((error) => {
        console.error("Error converting Word file:", error);
      });
  };

  const processDOM = async (fn: string, docHtml: string) => {
    // 解析HTML并提取所有段落文本
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(docHtml, "text/html");

    const paragraphsArray = Array.from(parsedHtml.querySelectorAll("p"));

    // 为每个段落添加一个唯一键并将其及其内容存储在一个新数组中
    // Add a unique key to each paragraph and store it along with its content in a new array
    const taggedParagraphsx = paragraphsArray
      .map((p, index) => {
        p.setAttribute("data-unique-key", index.toString());
        const trimmedText = (p.textContent || "").trim();
        return {
          seq: index,
          text: trimmedText,
          element: p
        };
      })
      .filter((p) => p.text !== "");

    //(taggedParagraphsx);

    const serializer = new XMLSerializer();
    const serializedContent = serializer.serializeToString(parsedHtml);

    await sendTextAndReceiveUpdates(fn, taggedParagraphsx);
  };

  const sendTextAndReceiveUpdates = async (
    filename: string,
    paras: taggedParagraph[]
  ) => {
    if (!filename) {
      console.log("filename is empty");
      return;
    }

    const args = {
      action: "upload",
      name: filename,
      paras: omitElement(paras),
      language,
      reviewOptions
    };

    await simpleAPICall(args, async (args: any, result: string) => {
      //updateParagraphs(args.paras as taggedParagraph[], result);
      //redirect to doc library
      const timer = setTimeout(() => {
        navigate(`/docs`);
      }, 1000);
    });
  };

  const uploadFile = async (data: FormData, name: string) => {
    const config = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total!
        );
        setProgress(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${process.env.REACT_APP_APIKEY}`
      }
    };

    setUploading(true);
    try {
      const url = process.env.REACT_APP_BASEURL + "/vc/v1/image/upload";
      await axios.post(url, data, config);
      console.log("File uploaded successfully");

      // parse docx, and upload paragraphes to server
    } catch (error) {
      console.log("Error uploading file:", error);
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setFile(file);

      const data = new FormData();
      data.append("file", file, encodeURIComponent(file.name!)); // 将编码后的文件名作为第三个参数
      data.append("veid", "M0003");
      uploadFile(data, file.name);
    }
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
  };

  const handleReviewOptionsChange = (values: string[]) => {
    setReviewOptions(values);
  };

  const handleSubmit = () => {
    if (file) {
      if (reviewOptions.length > 0) {
        setShowWarning(false);

        // parse docx, and upload paragraphes to server
        handleUpload({ file, language, reviewOptions });

        // use redirect
        //navigate(`/main?fileName=${encodeURIComponent(file.name)}`);
        //onUploaded({ file, language, reviewOptions });
      } else {
        setShowWarning(true);
      }
    }
  };

  return (
    <Flex
      width="100%"
      height="80vh" // 设置容器高度为视口高度
      alignItems="center" // 垂直居中
      justifyContent="center" // 水平居中
    >
      <Box maxWidth="md" margin="0 auto">
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>上传文件</FormLabel>
            <Input type="file" accept=".docx" onChange={handleFileSelect} />
          </FormControl>

          <FormControl>
            <FormLabel>文档主要语言</FormLabel>
            <Select value={language} onChange={handleLanguageChange}>
              <option value="zh-cn">简体中文</option>
              <option value="zh-hk">繁体中文</option>
              <option value="en-us">英语</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>需要审阅的内容</FormLabel>
            <CheckboxGroup
              value={reviewOptions}
              onChange={handleReviewOptionsChange}
            >
              <HStack spacing="24px">
                <Checkbox value="typo">改错别字</Checkbox>
                <Checkbox value="grammar">检查并改正语法错误</Checkbox>
                <Checkbox value="polish">专业润色</Checkbox>
              </HStack>
            </CheckboxGroup>
            {showWarning && (
              <Alert status="warning" mt={2}>
                <AlertIcon />
                请至少选择一项审阅内容。
              </Alert>
            )}
          </FormControl>

          <Button
            colorScheme={!file || uploading ? "gray" : "blue"}
            onClick={handleSubmit}
            disabled={!file || uploading}
          >
            提交
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default FileUploader;
