import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

interface EditableParagraphProps {
  initialText: string;
  onUpdate: (newText: string) => void;
  onUpdateLive?: (newText: string) => void;
}

const EditableParagraph: React.FC<EditableParagraphProps> = ({
  initialText,
  onUpdate,
  onUpdateLive
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputRef.current) {
      const newText = inputRef.current.value;
      setText(newText);
      onUpdate(newText);
    }
  };

  const handleLiveChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onUpdateLive) {
      onUpdateLive(e.target.value);
    }
    adjustTextAreaHeight();
  };

  const sharedStyles = {
    margin: "12px 0",
    padding: "12px"
  };

  useEffect(() => {
    setText(initialText);
    setIsEditing(false);
  }, [initialText]);

  const adjustTextAreaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  useLayoutEffect(() => {
    if (isEditing) {
      adjustTextAreaHeight();
    }
  }, [isEditing]);

  return isEditing ? (
    <textarea
      ref={inputRef as React.MutableRefObject<HTMLTextAreaElement>}
      defaultValue={text}
      onBlur={handleBlur}
      onChange={handleLiveChange}
      autoFocus
      style={{
        ...sharedStyles,
        width: "90%",
        height: "auto",
        overflow: "hidden",
        whiteSpace: "pre-wrap",
        resize: "none"
      }}
    />
  ) : (
    <p onDoubleClick={handleDoubleClick} style={sharedStyles}>
      {text}
    </p>
  );
};

export default EditableParagraph;
