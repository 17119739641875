import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { simpleAPICall } from "../common/sse";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Button
} from "@chakra-ui/react";
import DeleteConfirmation from "../components/DeleteConfirmation";

enum PaperStatus {
  New = "NEW",
  Working = "WORKING",
  Error = "ERROR",
  Completed = "COMPLETED"
}

interface Document {
  fileName: string;
  status: PaperStatus;
  progress: number;
  qualityScore: number;
}

const DocumentLibrary: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const navigate = useNavigate();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const handleDocumentClick = (fileName: string) => {
    navigate(`/main?fileName=${encodeURIComponent(fileName)}`);
  };

  const handleDeleteClick = async (name: string) => {
    setSelectedDocument(name);
    setIsAlertDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedDocument) {
      const args = {
        action: "delete",
        name: selectedDocument
      };

      await simpleAPICall(args, (args: any, result: string) => {
        if (result) {
          console.log("lib result is", result);
          const docs = JSON.parse(result) as Document[];
          setDocuments(docs);
        }
      });
    }

    setIsAlertDialogOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    const fetchDocuments = async () => {
      const args = {
        action: "list"
      };

      await simpleAPICall(args, (args: any, result: string) => {
        if (result) {
          console.log("lib result x is", result);
          if (result.startsWith("TypeError:")) {
            return;
          }
          const docs = JSON.parse(result) as Document[];
          setDocuments(docs);
        }
      });
    };

    setLoading(() => true);
    fetchDocuments();
  }, []);

  return (
    <Flex
      width="100%"
      height="80vh" // 设置容器高度为视口高度的 80%
      alignItems="center" // 垂直居中
      justifyContent="center" // 水平居中
    >
      <div>
        <h1>文档库</h1>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>文件名</Th>
              <Th>状态</Th>
              <Th>处理进度</Th>
              <Th>质量评分</Th>
              <Th>操作</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documents.map((document, index) => (
              <Tr key={index}>
                <Td>
                  <Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      handleDocumentClick(document.fileName);
                    }}
                  >
                    {document.fileName}
                  </Link>
                </Td>
                <Td>{document.status}</Td>
                <Td>{document.progress}%</Td>
                <Td>{document.qualityScore}%</Td>
                <Td>
                  {" "}
                  <Button
                    className=""
                    onClick={() => handleDeleteClick(document.fileName)}
                  >
                    删除
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <DeleteConfirmation
        isOpen={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        onDelete={handleDeleteConfirm}
        documentName={selectedDocument}
      />
    </Flex>
  );
};

export default DocumentLibrary;
