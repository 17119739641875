export type taggedParagraph = {
    seq: number;
    text: string;
    element: HTMLParagraphElement;
};

export type OmittedElementTaggedParagraph = Omit<taggedParagraph, "element">;

export enum RevState {
    AutoGen = "auto",
    Accepted = "accepted",
    Rejected = "rejected",
    Manual = "manual",
}

export type revParagraph = {
    seq: number;
    reason?: string;
    original_text?: string;
    revised_text?: string;
    state?: RevState;
};

export type revResult = {
    name: string;
    message: string;
    revs: revParagraph[];
};

export type StyleObject = React.CSSProperties;

export type ParagraphStyles = {
    [seq: number]: StyleObject;
};