import { diff_match_patch, DIFF_INSERT, DIFF_DELETE } from "diff-match-patch";
import { taggedParagraph, revParagraph } from "../types/words";

const dmp = new diff_match_patch();

export const showDiffInPTag = (containerDivID: string, rev: revParagraph, markSrcOnly: boolean) => {
    const targetDiv = document.getElementById(containerDivID);
    const srcParagraphs = Array.from(targetDiv!.querySelectorAll("p"));

    // 查找 data-unique-key 等于 rev.seq 的段落
    const targetParagraph = srcParagraphs.find((para) => {
        return para.getAttribute("data-unique-key") === rev.seq.toString();
    });

    if (targetParagraph) {
        // 在此处处理找到的目标段落
        //console.log("找到的目标段落：", targetParagraph);

        if (markSrcOnly) {
            const escapeHTML = (str: string) => {
                return str
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#39;");
            };

            const escapedReason = escapeHTML(rev.reason ?? "");

            const color = !rev.state || rev.state === "auto" ? "red" : "green";

            if (targetParagraph.innerHTML.indexOf(rev.original_text ?? "no such txt exists") >= 0) {
                const styledContent = `<span title="${escapedReason}" style="text-decoration: underline ${color};">${rev.original_text}</span>`;
                targetParagraph.innerHTML = targetParagraph.innerHTML.replace(rev.original_text!, styledContent);
            }
            else {
                // mark whole paragraph
                const styledContent = `<span title="${escapedReason}" style="text-decoration: underline ${color};">${targetParagraph.innerHTML}</span>`;
                targetParagraph.innerHTML = styledContent;
            }
        }
        else {
            targetParagraph.innerHTML = getRevisionHtml(rev);
        }
    } else {
        console.warn("未找到匹配的段落", rev.seq);
    }
}

export const getRevisionHtml = (
    rev: revParagraph | undefined
) => {
    if (rev === undefined || rev.original_text === undefined || rev.revised_text === undefined) {
        return "revision object not complete!";
    }

    return getRevisionHtmlForText(rev.original_text, rev.revised_text, rev.reason);
};

export const getRevisionHtmlForText = (original_text: string, revised_text: string, reason: string | undefined) => {
    // 计算原始文本和更改后的文本之间的差异
    const diffs = dmp.diff_main(original_text, revised_text);
    dmp.diff_cleanupSemantic(diffs);

    const escapeHTML = (str: string) => {
        return str
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    };

    const escapedReason = escapeHTML(reason ?? "");

    // 根据差异类型为文本添加适当的样式
    const styledContent = diffs
        .map((diff) => {
            const [type, text] = diff;
            switch (type) {
                case DIFF_INSERT:
                    return `<span title="${escapedReason}" style="background-color: lightgreen; text-decoration: underline green;">${text}</span>`;
                case DIFF_DELETE:
                    return `<span title="${escapedReason}" style="background-color: lightcoral; text-decoration: underline red;">${text}</span>`;
                default:
                    return text;
            }
        })
        .join("");

    return styledContent;
}