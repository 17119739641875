import { useEffect, useState, ChangeEvent, MouseEvent } from "react";
import {
  Button,
  Heading,
  Stack,
  StackDivider,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import {
  taggedParagraph,
  revResult,
  ParagraphStyles,
  revParagraph,
  OmittedElementTaggedParagraph,
  RevState
} from "../types/words";
import {
  getRevisionHtml,
  getRevisionHtmlForText,
  showDiffInPTag
} from "../common/paragraphDiff";
import "./reviewCard.css";
import EditableParagraph from "../common/editableParagraph";

const presetPhrases = [
  "修改错别字和语法错误",
  "让这段更专业",
  "使句子更简洁",
  "增加说服力",
  "在英文的基础上修改错别字和语法错误，不要翻译中文，保留英文"
];

interface ReviewCardProps {
  curpara?: taggedParagraph;
  currev?: revParagraph;
  onAccept: (seq?: number, state?: RevState, revised_text?: string) => void;
  onRedo?: (seq: number, reqest: string) => void;
  busy?: boolean;
  visible?: boolean;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  curpara,
  currev,
  onAccept,
  onRedo,
  busy,
  visible
}) => {
  const [showInput, setShowInput] = useState(false);
  const [userRequest, setUserRequest] = useState("");
  const [diffHtml, setDiffHtml] = useState<string | undefined>(undefined);

  useEffect(() => {
    const diffResult = getRevisionHtml(currev);
    setDiffHtml(diffResult);
    setShowInput(false);
    setUserRequest("");
  }, [currev]);

  const handleClickAccept = (e: MouseEvent<HTMLButtonElement>) => {
    onAccept(currev?.seq, RevState.Accepted, currev?.revised_text);
  };

  const handleClickReject = (e: MouseEvent<HTMLButtonElement>) => {
    onAccept(currev?.seq, RevState.Rejected, currev?.revised_text);
  };

  const handleClickReReview = (e: MouseEvent<HTMLButtonElement>) => {
    setShowInput(!showInput);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserRequest(e.target.value);
  };

  const handleClickStart = (e: MouseEvent<HTMLButtonElement>) => {
    // 在此处执行服务器调用，例如使用fetch API
    // 你可以使用 userRequest 变量作为输入参数
    console.log("开始按钮被点击，用户输入：", userRequest);
    if (curpara && onRedo) {
      onRedo(curpara.seq, userRequest);
    }
  };

  const handleTextUpdate = (newText: string) => {
    console.log("Updated text:", newText);
    // 在这里调用你的 API 更新函数
    if (currev) {
      onAccept(currev?.seq, RevState.Manual, newText);
    }
  };

  const handleLiveTextUpdate = (newText: string) => {
    //console.log("Live updated text:", newText);
    // 在这里处理实时更新的文本，例如将其显示在其他地方或实时验证
    if (currev) {
      //currev.revised_text = newText;
      setDiffHtml(
        getRevisionHtmlForText(
          currev.original_text ?? "",
          newText,
          "Manual Edit"
        )
      );
    }
  };

  const handlePresetPhraseClick = (phrase: string) => {
    setUserRequest(phrase);
  };

  const renderLoadingSpinner = () => {
    if (busy) {
      return (
        <div className="loading-spinner-container">
          <i className="fas fa-spinner fa-spin" />
        </div>
      );
    }
    return null;
  };

  const renderRevState = () => {
    if (!currev) {
      return null;
    }

    let stateClassName = "";
    let stateText = "";

    switch (currev.state) {
      case RevState.AutoGen:
        stateClassName = "state-auto";
        stateText = "自动生成";
        break;
      case RevState.Accepted:
        stateClassName = "state-accepted";
        stateText = "已接受";
        break;
      case RevState.Rejected:
        stateClassName = "state-rejected";
        stateText = "已拒绝";
        break;
      case RevState.Manual:
        stateClassName = "state-manual";
        stateText = "手动修改";
        break;
      default:
        stateClassName = "state-auto";
        stateText = "自动生成";
        break;
    }

    return <div className={`rev-state ${stateClassName}`}>{stateText}</div>;
  };

  const showInputDiv = () => {
    return (
      showInput && (
        <div>
          <div className="input-container">
            <input
              type="text"
              value={userRequest}
              placeholder="请输入要求"
              onChange={handleInputChange}
            />
            <Button
              className="start"
              disabled={userRequest.trim() === ""}
              onClick={handleClickStart}
            >
              开始
            </Button>
          </div>
          <Wrap spacing={3} marginTop={4}>
            {presetPhrases.map((phrase, index) => (
              <WrapItem key={index}>
                <Button
                  className="preset-phrase"
                  onClick={() => handlePresetPhraseClick(phrase)}
                  fontWeight="normal"
                >
                  {phrase}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
        </div>
      )
    );
  };

  return visible ? (
    <VStack
      direction="row"
      justifyContent="center"
      spacing={3}
      marginTop={4}
      divider={<StackDivider borderColor="gray.200" />}
    >
      <Heading size="lg">修订建议</Heading>

      {currev !== undefined ? (
        <VStack
          direction="row"
          justifyContent="center"
          spacing={3}
          marginTop={4}
        >
          <p
            data-unique-key={currev.seq}
            dangerouslySetInnerHTML={{ __html: diffHtml || "" }}
          />
          <i className="fas fa-arrow-down" />
          <Heading size="sm">{currev.reason}</Heading>
          <i className="fas fa-arrow-down" />
          <EditableParagraph
            initialText={currev.revised_text ?? ""}
            onUpdate={handleTextUpdate}
            onUpdateLive={handleLiveTextUpdate}
          />
          {renderRevState()}
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            marginTop={4}
          >
            {!currev.state || currev.state === "auto" ? (
              <>
                <Button className="accept" onClick={handleClickAccept}>
                  接受
                </Button>
                <Button className="ignore" onClick={handleClickReject}>
                  忽略
                </Button>
              </>
            ) : (
              <div></div>
            )}

            <Button className="re-review" onClick={handleClickReReview}>
              重新审阅
            </Button>
          </Stack>

          {renderLoadingSpinner()}

          {showInputDiv()}
        </VStack>
      ) : (
        <VStack
          direction="row"
          justifyContent="center"
          spacing={3}
          marginTop={4}
        >
          <p>{curpara?.text}</p>
          <Heading size="sm">没有可显示的修订</Heading>
          <Button className="re-review" onClick={handleClickReReview}>
            重新审阅
          </Button>
          {showInputDiv()}
        </VStack>
      )}
    </VStack>
  ) : (
    <></>
  );
};

export default ReviewCard;
