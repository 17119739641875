import { createParser } from 'eventsource-parser'
import { Query } from './query'

interface FetchSSEOptions extends RequestInit {
    onMessage(data: string): void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any): void
    onStatusCode?: (statusCode: number) => void
    fetcher?: (input: string, options: RequestInit) => Promise<Response>
}

async function fetchSSE(input: string, options: FetchSSEOptions) {
    const { onMessage, onError, onStatusCode, fetcher = fetch, ...fetchOptions } = options

    const resp = await fetcher(input, fetchOptions)
    onStatusCode?.(resp.status)
    if (resp.status !== 200) {
        onError(await resp.json())
        return
    }

    const parser = createParser((event) => {
        if (event.type === 'event') {
            onMessage(event.data)
        }
    })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reader = resp.body!.getReader()
    try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const { done, value } = await reader.read()
            if (done) {
                break
            }
            const str = new TextDecoder().decode(value)
            parser.feed(str)
        }
    } finally {
        reader.releaseLock()
    }
}

export const handleSSE = async (url: string, body: {}, query: Query) => {
    const headers: Record<string, string> = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_APIKEY}`
    };

    let isWordMode = false;
    let length = 0;

    await fetchSSE(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
        onStatusCode: (status) => {
            query.onStatusCode?.(status);
        },
        onMessage: (msg) => {
            let resp;
            if (msg === "[DONE]") {
                query.onFinish('stop');
                return;
            }

            try {
                resp = JSON.parse(msg)

                // eslint-disable-next-line no-empty
            } catch {
                query.onFinish('stop')
                return
            }

            const { choices } = resp
            if (!choices || choices.length === 0) {
                return { error: 'No result' }
            }
            const { finish_reason: finishReason } = choices[0]
            if (finishReason) {
                query.onFinish(finishReason)
                return
            }

            let targetTxt = ''

            const { content = '', role } = choices[0].delta

            targetTxt = content

            // if (quoteProcessor) {
            //     targetTxt = quoteProcessor.processText(targetTxt)
            // }

            query.onMessage({ content: targetTxt, role, isWordMode })
        },
        onError: (err) => {
            if (err instanceof Error) {
                query.onError(err.message)
                return
            }
            if (typeof err === 'string') {
                query.onError(err)
                return
            }
            if (typeof err === 'object') {
                const { detail } = err
                if (detail) {
                    query.onError(detail)
                    return
                }
            }
            const { error } = err
            if (error instanceof Error) {
                query.onError(error.message)
                return
            }
            if (typeof error === 'object') {
                const { message } = error
                if (message) {
                    query.onError(message)
                    return
                }
            }
            query.onError('Unknown error')
        },
    });
};

export const simpleAPICall = async (args: any, onFinished?: (args: {}, result: string) => void, signal?: AbortSignal) => {
    const data = {
        version: 4,
        veid: "M0003",
        stream: true,
        messages: [
            {
                role: "app",
                content: args
            }
        ]
    };

    let respText = "";
    const query: Query = {
        signal: signal,
        text: "",
        selectedWord: "",
        onStatusCode: (statusCode) => {
            console.log("onStatusCode", statusCode);
        },
        onMessage: (message) => {
            if (message.role) {
                return;
            }
            console.log("onMessage", message);

            if (message.content === "all finished.") {
                console.log("done.");
            } else {
                respText += message.content;
            }
        },
        onFinish: (reason) => {
            console.log("onFinish", reason, "respText is:", respText);
            if (onFinished) {
                onFinished(args, respText);
            }
            respText = "";
        },
        onError: (error) => {
            console.log("onError", error);
        }
    };

    await handleSSE(process.env.REACT_APP_BASEURL + "/vc/v1/chat", data, query);
};