import React, { useEffect, useState } from "react";

import styles from "./main.module.css"; // Import CSS module
import { simpleAPICall } from "../common/sse";
import {
  taggedParagraph,
  revResult,
  ParagraphStyles,
  revParagraph,
  RevState
} from "../types/words";
import { showDiffInPTag } from "../common/paragraphDiff";
import ReviewCard from "./reviewCard";
import { omitElement } from "../common/utils";
import FileUploader from "./FileUploader";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import mammoth from "mammoth";

const WordViewer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [filename, setFileName] = useState<string>("");
  const [language, setLanguage] = useState<string>("简体中文");
  const [reviewOptions, setReviewOptions] = useState<string[]>([]);
  const [convertedDocument, setConvertedDocument] = useState<string>("");
  const [updateProcessDOM, setUpdateProcessDOM] = useState(false);
  const [taggedParagraphs, setTaggedParagraphs] = useState<taggedParagraph[]>(
    []
  );
  const [revresult, setRevresult] = useState<revResult | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const controller = new AbortController();
  const [serializedHtml, setSerializedHtml] = useState("");
  const [paragraphStyles, setParagraphStyles] = useState<ParagraphStyles>({});
  const [revParasPosition, setRevParasPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 800, y: 0 });
  const [curseq, setCurseq] = useState<number>(0);
  const [curpara, setCurpara] = useState<taggedParagraph | undefined>(
    undefined
  );
  const [currev, setCurrev] = useState<revParagraph | undefined>(undefined);
  const [apiBusy, setApiBusy] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fileNameParam = queryParams.get("fileName");

    if (fileNameParam) {
      setFileName(decodeURIComponent(fileNameParam));

      const filename = decodeURIComponent(fileNameParam);
      // 获取文件数据的异步函数
      const getFileData = async () => {
        try {
          // 对文件名进行 URL 编码
          const encodedFilename = encodeURIComponent(filename);

          // 使用 axios 发起请求
          const response = await axios.get(
            `${process.env.REACT_APP_BASEURL}/vc/v1/image/download?fn=${encodedFilename}`,
            {
              headers: {
                // 添加 JWT 验证头部（根据你的需求进行修改）
                Authorization: `Bearer ${process.env.REACT_APP_APIKEY}`
              },
              responseType: "arraybuffer" // 设置响应类型为 ArrayBuffer
            }
          );

          // 将文件数据存储在组件状态中
          handleFileRead(filename, response.data);
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      };

      getFileData();
    }
  }, [location.search]);

  useEffect(() => {
    revresult?.revs.forEach((p) => {
      showDiffInPTag("paper", p, true);
    });
  }, [revresult]);

  useEffect(() => {
    const rev = revresult?.revs?.find((a) => a?.seq === curseq);
    setCurrev(rev);
    const para = taggedParagraphs?.find((a) => a?.seq === curseq);
    setCurpara(para);
  }, [curseq, revresult]);

  const handleFileRead = (fn: string, file: ArrayBuffer) => {
    mammoth
      .convertToHtml({ arrayBuffer: file })
      .then((result) => {
        const docHtml = result.value;
        //setConvertedDocument(docHtml);
        //setFileName(fn);
        processDOM(fn, docHtml);

        const args = {
          action: "download",
          name: fn
        };

        simpleAPICall(args, (args, result) => {
          console.log("download result", result);
          if (result) {
            const resultx = JSON.parse(result);
            setRevresult(resultx);
            console.log("download", resultx);
          }
        })
          .then((res) => {
            console.log(res);
            // setReviewOptions(res.reviewOptions);
            // setRevresult(res.revresult);
            // setParagraphStyles(res.paragraphStyles);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.error("Error converting Word file:", error);
      });
  };

  const processDOM = (fn: string, docHtml: string) => {
    // 解析HTML并提取所有段落文本
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(docHtml, "text/html");

    const paragraphsArray = Array.from(parsedHtml.querySelectorAll("p"));

    // 为每个段落添加一个唯一键并将其及其内容存储在一个新数组中
    // Add a unique key to each paragraph and store it along with its content in a new array
    const taggedParagraphsx = paragraphsArray
      .map((p, index) => {
        p.setAttribute("data-unique-key", index.toString());
        const trimmedText = (p.textContent || "").trim();
        return {
          seq: index,
          text: trimmedText,
          element: p
        };
      })
      .filter((p) => p.text !== "");

    setTaggedParagraphs(taggedParagraphsx);

    const serializer = new XMLSerializer();
    const serializedContent = serializer.serializeToString(parsedHtml);
    setSerializedHtml(serializedContent);
  };

  const updateUnderlineColor = (seq: number, color: string) => {
    const targetParagraph = findParagraphByUniqueKey(seq);
    if (!targetParagraph) return;

    // 查找所有 <span> 标签
    const spanTags = targetParagraph.querySelectorAll("span");

    // 遍历找到的标签并检查是否具有红色下划线样式
    spanTags.forEach((span) => {
      const textDecoration = (span as HTMLElement).style.textDecoration;
      const textDecorationColor = (span as HTMLElement).style
        .textDecorationColor;

      (span as HTMLElement).style.textDecorationColor = color;
      // if (
      //   //textDecoration === "underline" &&
      //   textDecorationColor.toLowerCase() === "red"
      // ) {
      //   // 将红色下划线更改为绿色下划线
      //   (span as HTMLElement).style.textDecorationColor = "green";
      // }
    });
  };

  const findParagraphByUniqueKey = (uniqueKey: number) => {
    const targetDiv = document.getElementById("paper");
    const paragraph = targetDiv?.querySelector(
      `p[data-unique-key="${uniqueKey}"]`
    );
    return paragraph;
  };

  const getLastElement = (arr: any[]): any | null => {
    return arr.length > 0 ? arr[arr.length - 1] : null;
  };

  const handleReviewOnAccept = async (
    seq?: number,
    state?: RevState,
    revised_text?: string
  ) => {
    if (!seq || !currev) {
      return;
    }

    // 需要更新Review Card的UI
    const revNew = { ...currev, state, revised_text };
    setCurrev(revNew);

    // update results also
    addOrUpdateRev(revNew);

    // 2.1 update server
    const args = {
      action: "accept",
      name: filename,
      revs: [revNew]
    };

    await simpleAPICall(args);

    // 3. 寻找下一个有待审阅的段落，并跳过去
    for (let i = seq + 1; i <= getLastElement(taggedParagraphs).seq; i++) {
      const nextpara = findParagraphByUniqueKey(i);
      if (nextpara && nextpara.innerHTML.indexOf("underline red") > 0) {
        jumpToParagraph(nextpara as HTMLElement);
        return;
      }
    }

    // 如果没有下一个待审阅的段落，可以执行其他操作，例如显示消息或导航到其他页面
    console.log("No more paragraphs to review.");
  };

  const handleRedoReview = async (seq: number, request: string) => {
    const targetParagraph = taggedParagraphs.find((p) => p.seq === seq);
    if (!targetParagraph) return;

    const args = {
      action: "redo",
      name: filename,
      prompt: request,
      paras: [targetParagraph]
    };

    const revNew = {
      seq: targetParagraph.seq,
      state: RevState.AutoGen,
      reason: request,
      original_text: targetParagraph.text,
      revised_text: ""
    };
    setCurrev(revNew);

    setApiBusy(true);

    await simpleAPICall(args, (args, result) => {
      console.log("Redo Review result", result);
      if (result && result.length > 0) {
        const revNext = { ...revNew, revised_text: result };
        addOrUpdateRev(revNext);
        setCurrev((old) => revNext);
      }
      setApiBusy((prev) => false);
    });
  };

  const addOrUpdateRev = async (revNew: revParagraph) => {
    setRevresult((prevRevresult) => {
      if (!prevRevresult) {
        console.log("prevRevresult is null");
        return null;
      }

      const updated = {
        ...prevRevresult,
        revs: prevRevresult!.revs.map((rev) => {
          if (rev.seq === revNew.seq) {
            return revNew;
          } else {
            return rev;
          }
        })
      };

      if (!updated.revs.find((a) => a.seq === revNew.seq)) {
        // push new rev
        updated.revs.push(revNew);
      }

      return updated;
    });

    if (
      revNew.state === RevState.Accepted ||
      revNew.state === RevState.Rejected
    ) {
      updateUnderlineColor(revNew.seq, "green");
    } else if (revNew.state === RevState.AutoGen) {
      // redo the diff
      showDiffInPTag("paper", revNew, true);
    }
  };

  const generateDefaultStyles = (revs: revParagraph[]): ParagraphStyles => {
    const styles: ParagraphStyles = {};

    revs.forEach((paragraph) => {
      if (paragraph?.seq) {
        styles[paragraph.seq] = {
          display: "none"
        };
      }
    });

    return styles;
  };

  // 添加一个新的状态变量来存储当前选择的 seq
  const [selectedSeq, setSelectedSeq] = useState<number | null>(null);

  const selectRightPara = (seq: number) => {
    console.log("set style for seq", seq);

    setCurseq(seq);

    setParagraphStyles((prevState) => {
      // 如果 selectedSeq 不为 null，则将先前选择的段落样式重置为默认样式
      if (selectedSeq !== null) {
        prevState[selectedSeq] = {
          display: "none"
        };
      }

      // 更新新选择的 seq 的样式
      prevState[seq] = {
        display: "block"
        // color: "green",
        // fontSize: "20px",
        // backgroundColor: "lightgreen"
      };

      return { ...prevState };
    });

    // 更新 selectedSeq 为新选择的 seq
    setSelectedSeq(seq);
  };

  const uniqueRevParagraphs = (
    revParagraphs: revParagraph[]
  ): revParagraph[] => {
    const seenSeqs = new Map<number, revParagraph>();

    revParagraphs.forEach((revPara) => {
      if (revPara?.seq && !seenSeqs.has(revPara.seq)) {
        seenSeqs.set(revPara.seq, revPara);
      }
    });

    return Array.from(seenSeqs.values());
  };

  const updateParagraphs = (
    paras: taggedParagraph[],
    revsuggestion: string
  ) => {
    // 处理新数组并将结果更新到原始HTML文档中
    try {
      JSON.parse(revsuggestion);
    } catch (err) {
      console.log("Error parse suggestion result:", revsuggestion);
      return;
    }

    const result = JSON.parse(revsuggestion) as revResult;

    result.revs = uniqueRevParagraphs(result.revs);

    console.log("Server message is:", result.message);
    const revs = result.revs;

    setRevresult(result);
    // update the style array
    setParagraphStyles(generateDefaultStyles(revs));

    // 处理新数组并将结果更新到原始HTML文档中
    const processedParagraphs = paras.map((paragraph) => {
      const rev = revs.find((r) => r?.seq === paragraph.seq);
      if (rev) {
        showDiffInPTag("paper", rev, true);
      }
    });

    // const nonsense = revs.map((paragraph) => {
    //   const rev = revs.find((r) => r?.seq === paragraph?.seq);
    //   if (rev) {
    //     showDiffInPTag("revparas", rev, false);
    //   }
    // });
  };

  const findParentParagraph = (
    element: HTMLElement | null
  ): HTMLElement | null => {
    while (element && element.tagName !== "P") {
      element = element.parentElement;
    }
    return element;
  };

  function getRelativePosition(
    element: HTMLElement,
    parent: HTMLElement
  ): { x: number; y: number } {
    const elementRect = element.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    const x = elementRect.left - parentRect.left;
    const y = elementRect.top - parentRect.top;

    return { x, y };
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedElement = event.target as HTMLElement;
    jumpToParagraph(clickedElement);
  };

  const jumpToParagraph = (ele: HTMLElement) => {
    const clickedElement = ele;
    const clickedParagraph = findParentParagraph(clickedElement);

    if (clickedParagraph) {
      const uniqueKey = clickedParagraph.getAttribute("data-unique-key");
      console.log("Clicked paragraph with unique key:", uniqueKey);
      selectRightPara(+uniqueKey!);

      console.log("styles.parent:", styles.parent);

      // 获取 styles.parent 对应的 DOM 元素
      const parentElement = clickedElement.closest(
        `.${styles.parent}`
      ) as HTMLElement;

      // 使用 getRelativePosition 函数获取坐标
      if (parentElement) {
        const rect = getRelativePosition(clickedParagraph, parentElement);
        const x = rect.x;
        const y = rect.y - 92;

        // 更新 revParasPosition 状态变量
        setRevParasPosition({ x, y });

        // 计算要滚动的距离，以使段落在屏幕上方的黄金分割线位置
        const windowHeight = window.innerHeight;
        const goldenRatio = 0.768;
        const scrollDistance =
          y -
          windowHeight * (1 - goldenRatio) +
          clickedParagraph.clientHeight / 2;

        // 使用 window.scrollTo 方法将页面滚动到计算出的位置
        window.scrollTo({ top: scrollDistance, behavior: "smooth" });
      }
    }
  };

  const handleUploaded = (options: {
    file: File;
    language: string;
    reviewOptions: string[];
  }) => {
    console.log("handleUploaded:", options);
  };

  return (
    <div>
      {filename === "" && <FileUploader onUploaded={handleUploaded} />}

      <div className={styles.parent}>
        <div className={styles.leftcolumn}>
          <div
            key={refreshKey}
            id="paper"
            className={styles.layout}
            onClick={handleClick}
            dangerouslySetInnerHTML={{ __html: serializedHtml }}
          ></div>
        </div>
        <div className={styles.rightcolumn}>
          <div
            style={{
              height: revParasPosition.y
              // position: "absolute",
              // left: revParasPosition.x + "px",
              // top: revParasPosition.y + "px"
            }}
          >
            &nbsp;
          </div>
          <div id="revparas">
            <ReviewCard
              curpara={curpara}
              currev={currev}
              busy={apiBusy}
              onAccept={handleReviewOnAccept}
              onRedo={handleRedoReview}
              visible={curpara !== undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordViewer;
