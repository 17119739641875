// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WordViewer from "./ui/main";
import "./App.css";
import DocumentLibrary from "./ui/docLib";
import NavBar from "./ui/NavBar";
import HomePage from "./ui/HomePage";
import {
  ChakraBaseProvider,
  ChakraProvider,
  ColorModeProvider,
  ThemeProvider,
  extendBaseTheme
} from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";

const { Button } = chakraTheme.components;

const theme = extendBaseTheme({
  components: {
    Button
  }
});

const App = () => (
  <ChakraProvider>
    <App2 />
  </ChakraProvider>
);

function App2() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/main" element={<WordViewer />} />
            <Route path="/docs" element={<DocumentLibrary />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;
